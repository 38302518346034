<template>
  <div class="box" :style="styleD">
    <!-- 素材文件夹  分组树形图 -->
    <No v-if="data.length === 0"></No>
    <el-tree
      v-else
      :data="data"
      default-expand-all
      :highlight-current="true"
      :props="defaultProps"
      :expand-on-click-node="false"
      node-key="id"
      ref="treeForm"
      empty-text=""
      @node-click="clickNode"
    >
      <template v-slot="{ data }">
        <div class="custom-tree-node">
          <img v-show="!data.checked" src="@/assets/image/wenjianjia.png" alt="" />
          <img v-show="data.checked" src="@/assets/image/smallJia.png" alt="" />
          <span :style="{ color: data.checked ? '#409eff' : '' }">{{ data.name }}</span>
        </div>
      </template>
    </el-tree>
  </div>
</template>

<script>
import No from './no.vue'
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          children: '_child',
          label: 'name'
        }
      }
    },
    styleD: {
      type: Object,
      default: () => {
        return {
          width: ' 300px',
          height: '400px'
        }
      }
    }
  },
  data() {
    return {
      checkedId: '',
      nowThis: null,
      first: true
    }
  },
  methods: {
    dealFnArr(arr, callback = () => {}) {
      arr.map((item) => {
        callback(item)
        if (item._child) {
          this.dealFnArr(item._child, callback)
        }
      })
    },
    // 设置默认项
    setDefaule(item) {
      if (item && item.id == -2) {
        item.id = -1
      }
      this.$nextTick(() => {
        if (item) {
          this.dealFnArr(this.data, (item1) => {
            if (item.id === item1.id) {
              item1.checked = true
              this.nowThis = item1
            } else {
              item1.checked = false
            }
          })
        } else {
          this.nowThis.checked = false
          this.nowThis = null
        }
      })
    },
    clickNode(node) {
      if (this.nowThis) {
        this.nowThis.checked = false
      }
      node.checked = true
      this.nowThis = node
      this.$emit('resultGroup', node)
    }
  },
  components: { No }
  
}
</script>

<style lang="less" scoped>
.box {
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .el-tree {
    padding: 0 20px 20px 0;
    min-width: 100%;
    display: inline-block !important;
    img {
      margin-right: 5px;
    }
    .custom-tree-node {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
    }
    & /deep/ .el-tree-node__content:hover {
      background-color: #fff;
    }
    & /deep/ .is-current {
      & > .el-tree-node__content {
        // color: #409eff;
        background-color: #fff;
      }
    }
  }
}
</style>
